import React from 'react';
import * as Yup from 'yup';
import Section from './Section';
import { useFormik } from 'formik';
import LoginForm from './LoginForm';
import RouterLink from 'next/link'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Box,
  Container,
  Typography
} from '@material-ui/core';
import { useAuth } from '@contexts/auth'
import { useTranslation } from '@contexts/translation';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    maxWidth: '100%'
  },
  header: {
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(7, 5, 0, 7)
    }
  },
  content: {
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  }
}));


function LoginView() {
  const classes = useStyles();
  const { login } = useAuth()
  const { dictionary } = useTranslation()

  const hiddenSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const hiddenMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const hiddenSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));




  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: sessionStorage.getItem('user_email') || '',
      password: '',
      remember: Boolean(sessionStorage.getItem('remember_me')) || false,
      mode: 'onBlur',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      if (values.remember) {
        sessionStorage.setItem('user_email', values.email)
        sessionStorage.setItem('remember_me', values.remember)
      }
      const response = await login(
        {
          email: values.email,
          password: values.password
        }
      )
      if (response) {
        formik.setErrors(response)
      }
    }
  });

  return (
    <Container title="Login" className={classes.root}>
      <header className={classes.header}>
        {hiddenSmDown ? null : <Typography
          variant="body2"
          sx={{
            mt: { md: -2 },
          }}
        >
          <RouterLink
            href='/signup'
          >
            {dictionary.routes.login['link_signUp']}
          </RouterLink>
        </Typography>}

      </header>

      {hiddenMdDown ? null : <Section />
      }

      <Container maxWidth="bg">
        <div className={classes.content}>
          <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {dictionary['sign_in']}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                {dictionary['enter_details']}
              </Typography>
            </Box>
          </Box>

          <LoginForm formik={formik} />

          {hiddenSmUp ? null : <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            <RouterLink
              href='/signup'
            >
              {dictionary.routes.login['link_signUp']}
            </RouterLink>
          </Typography>}

        </div>
      </Container>
    </Container>
  );
}

export default LoginView;
