import React, {useState} from 'react'
import {useForm} from 'react-hook-form'

import Image from 'next/image'

import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Skeleton from '@material-ui/lab/Skeleton'

import {Copyright} from '@components/common'

import {onForgotPassword} from '@api/auth'

import {useTranslation} from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  containerImage: {
    display: 'block',
    width: '320px',
    height: '320px',
    margin: '0 auto',
    marginBottom: theme.spacing(2),
  },
}))

export default function ForgotPassordView() {
  const {dictionary} = useTranslation()
  const classes = useStyles()
  const [emailSent, setEmailSent] = useState(false)

  const {register, handleSubmit, setError, errors} = useForm({
    mode: 'onBlur',
  })

  const onSubmit = async (data) => {
    let response = await onForgotPassword(data)
    if (response) {
      if (response.status === 400) {
        const errors = response.data.errors
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      } else {
        setEmailSent(true)
      }
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.containerImage}>
          <Image
            src="/static/illustrations/illustration_500.svg"
            alt="Login illustration"
            width={320}
            height={320}
            layout="responsive"
          />
        </div>
        <br />

        <Typography component="h1" variant="h5">
          {dictionary.routes.forgot['title']}
        </Typography>
        {emailSent ? (
          <div>{dictionary.routes.forgot['email_sent']}</div>
        ) : (
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="email"
              id="email"
              label={dictionary['email_address']}
              name="email"
              autoComplete="email"
              inputRef={register({
                required: dictionary['errors_email_required'],
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: dictionary['errors_email_pattern'],
                },
              })}
              error={errors.email}
              helperText={errors.email && errors.email.message}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {dictionary.routes.forgot['request_cta']}
            </Button>
          </form>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
