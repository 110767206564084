import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
import { useTranslation } from '@contexts/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
  }
}));

function Section({ className }) {
  const classes = useStyles();
  const { dictionary } = useTranslation()

  return (
    <Card className={clsx(classes.root, className)} sx={{ display: { md: 'none', sm: 'none', xs: 'none' } }}>
      <Typography variant="h3" sx={{ px: 5, mt: 5, mb: 2 }}>
        {dictionary['welcome_back']}
      </Typography>
      <img src="/static/illustrations/illustration_login.svg" alt="login" />
    </Card>
  );
}

export default Section;
