import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import Router from 'next/router'

import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import { Copyright } from '@components/common'

import { onResetPassword } from '@api/auth'

import { Alert, AlertTitle } from '@material-ui/lab'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ResetPasswordView({ resetData }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()

  const { register, handleSubmit, setError, errors, watch } = useForm({
    mode: 'onBlur',
  })

  const password = useRef({})
  password.current = watch('new_password', '')

  const onSubmit = async (data) => {
    const formattedData = { ...data, token: resetData[1], uid: resetData[0] }
    let response = await onResetPassword(formattedData)
    if (response) {
      if (response.status === 400) {
        const errors = response.data.errors
        if (errors.length > 0) {
          errors.map((error) => {
            if (error.field !== 'uid' && error.field !== 'token') {
              setError(error.field, {
                message: error.message,
              })
            } else {
              // Set error and add block error message before button
              console.log(error.message)
            }
          })
        }
      } else {
        Router.push('/login')
      }
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {dictionary.routes.resetPassword["title"]}
        </Typography>
        {resetData && resetData.length > 1 ? (
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="new_password"
              label={dictionary["new_password"]}
              name="new_password"
              autoComplete="password"
              type="password"
              inputRef={register({
                required: dictionary["errors_password_required"],
                minLength: {
                  value: 8,
                  message: dictionary["errors_password_minLength"],
                },
              })}
              error={errors.new_password}
              helperText={errors.new_password && errors.new_password.message}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="new_password2"
              label={dictionary["confirm_password"]}
              name="new_password2"
              autoComplete="password"
              type="password"
              inputRef={register({
                validate: (value) =>
                  value === password.current || dictionary["errors_password_not_match"],
              })}
              error={errors.new_password2}
              helperText={errors.new_password2 && errors.new_password2.message}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {dictionary.routes.resetPassword["cta"]}
            </Button>
          </form>
        ) : (
          <>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {dictionary.routes.resetPassword["alert_text"]}
            </Alert>
          </>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
