import React, { useState } from 'react';
import * as Yup from 'yup';
import Section from './Section';
import { useFormik } from 'formik';
import RegisterForm from './RegisterForm';
import RouterLink from 'next/link'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Link, Container, Typography } from '@material-ui/core';
import { onSignup } from '@api/auth';
import { useTranslation } from '@contexts/translation'


const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    maxWidth: '100%'
  },
  header: {
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(7, 5, 0, 7)
    }
  },
  content: {
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  }
}));

// ----------------------------------------------------------------------

function RegisterView() {
  const classes = useStyles();
  const [isDone, setIsDone] = useState(false)
  const { dictionary } = useTranslation()

  const hiddenSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const hiddenMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const hiddenSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const RegisterSchema = Yup.object().shape({
    // first_name: Yup.string()
    //   .min(2, 'Too Short!')
    //   .max(50, 'Too Long!')
    //   .required('First name required'),
    // last_name: Yup.string()
    //   .min(2, 'Too Short!')
    //   .max(50, 'Too Long!')
    //   .required('Last name required'),
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await onSignup({
          email: values.email,
          password: values.password,
          // first_name: values.first_name,
          // last_name: values.last_name
        });
        setIsDone(true)
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ [error.field]: error.code || error.message });
          setSubmitting(false);
        }
      }
    }
  });

  return (
    <Container title="Register" className={classes.root}>
      <header className={classes.header}>

        {hiddenSmDown ? null : <Typography variant="body2" sx={{ mt: { md: -2 } }}>
          <RouterLink
            href='/login'
          >
            {dictionary.routes.signUp['link_login']}
          </RouterLink>
        </Typography>}

      </header>

      {hiddenMdDown ? null : <Section />
      }
      <Container>
        <div className={classes.content}>
          {isDone ?
            <>
              <br />
              <Typography variant="body2" component="a">
                {dictionary.routes.signUp["verification_email"]}
              </Typography>
            </>
            : (<><Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {dictionary['sign_up']}
                </Typography>
                {/* <Typography sx={{ color: 'text.secondary' }}>
                  Free forever. No credit card needed.
                </Typography> */}
              </Box>
            </Box>
              <RegisterForm formik={formik} />

              <Typography
                variant="body2"
                align="center"
                sx={{ color: 'text.secondary', mt: 3 }}
              >
                By registering, I agree to Keysy {' '}
                <Link underline="always" sx={{ color: 'text.primary' }}>
                  Terms of Service
                </Link>
                &nbsp;and&nbsp;
                <Link underline="always" sx={{ color: 'text.primary' }}>
                  Privacy Policy
                </Link>
                .
              </Typography>


              {hiddenSmUp ? null : <Box sx={{ mt: 3, textAlign: 'center' }}>
                <RouterLink
                  href={'/login'}
                >
                  {dictionary.routes.signUp['link_login']}
                </RouterLink>
              </Box>}


            </>)}
        </div>
      </Container>
    </Container>
  );
}

export default RegisterView;
