import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Section from './Section';
import { useFormik } from 'formik';
import RegisterForm from './InvitationForm';
import RouterLink from 'next/link'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Link, Container, Typography } from '@material-ui/core';
import { useTranslation } from '@contexts/translation'
import { postInvitationData, setPassword } from '@api/invitation';
import { useRouter } from 'next/router';
import { Alert, AlertTitle } from '@material-ui/lab'



const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    maxWidth: '100%'
  },
  header: {
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(7, 5, 0, 7)
    }
  },
  content: {
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
  }
}));

// ----------------------------------------------------------------------

function InvitationView({ id }) {
  const classes = useStyles();
  const [isDone, setIsDone] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [passwordCode, setPasswordCode] = useState(null)
  const { dictionary } = useTranslation()
  useEffect(() => {
    const useInvitationCode = async () => {
      let response = await postInvitationData(id)
      if (response.status === 200) {
        setIsValid(true)
        setIsLoading(false)

        setPasswordCode(response.data && response.data.code)
      }
      else {
        setIsLoading(false)
      }
    }
    useInvitationCode()
  }, [])

  const hiddenMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));
  const hiddenSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Last name required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });
  const router = useRouter()

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      confirmPassword: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await setPassword(passwordCode, values);
        router.push('/login')
      } catch (error) {
        console.error(error);
        setErrors({ afterSubmit: error.code || error.message });
        setSubmitting(false);
      }
    }
  });

  return (
    <Container title="Register" className={classes.root}>
      <header className={classes.header}>
        <Typography variant="body2" sx={{ mt: { md: -2 } }}>
          Already have an account? &nbsp;
          <RouterLink
            href='/login'
          >
            Login
          </RouterLink>
        </Typography>
      </header>

      {hiddenMdDown ? null : <Section />
      }
      <Container>
        <div className={classes.content}>
          {isDone ?
            <>
              <br />
              <Typography variant="body2" component="a">
                {dictionary.routes.signUp["verification_email"]}
              </Typography>
            </>
            : (
              <>
                {!isLoading && isValid ?
                  <>
                    <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h4" gutterBottom>
                          Get started.
                        </Typography>
                        {/* <Typography sx={{ color: 'text.secondary' }}>
                  Free forever. No credit card needed.
                </Typography> */}
                      </Box>
                    </Box>
                    <RegisterForm formik={formik} />

                    <Typography
                      variant="body2"
                      align="center"
                      sx={{ color: 'text.secondary', mt: 3 }}
                    >
                      By registering, I agree to Keysy
                      <Link underline="always" sx={{ color: 'text.primary' }}>
                        {' '}Terms of Service
                      </Link>
                      &nbsp;and&nbsp;
                      <Link underline="always" sx={{ color: 'text.primary' }}>
                        Privacy Policy
                      </Link>
                      .
                    </Typography>


                    {hiddenSmUp ? null : <Box sx={{ mt: 3, textAlign: 'center', display: { sm: 'none', xs: 'block' } }}>
                      Already have an account?&nbsp;
                      <RouterLink
                        href={'./login'}
                      >
                        Login
                      </RouterLink>
                    </Box>}
                  </> :
                  <>
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {dictionary.routes.resetPassword["alert_text"]}
                    </Alert>
                  </>
                }
              </>
            )}
        </div>
      </Container>
    </Container>
  );
}

export default InvitationView;
