import client from '@utils/client';

export const postInvitationData = (code) => {
  return client
    .post(`/invitations/invitations/accept/${code}/`, {})
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};
export const setPassword = (code, payload) => {
  return client
    .post(`/invitations/set_password/${code}/`, {
      ...payload
    })
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};