import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'

import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from '@material-ui/lab/Skeleton'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Button } from '@material-ui/core'

import { onActivation } from '@api/auth'
import { Copyright } from '@components/common'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  containerImage: {
    display: 'block',
    width: '320px',
    height: '320px',
    margin: '0 auto',
    marginBottom: theme.spacing(2),
  },
}))

export default function ActivationView({ activationToken }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [isActivated, setIsActivated] = useState(false)

  useEffect(() => {
    if (activationToken && activationToken.length > 1) {
      const onSubmit = async () => {
        const response = await onActivation({
          uid: activationToken[0],
          token: activationToken[1],
        })
        if (response) {
          setLoading(false)
          if (response.status === 400) {
            const { errors } = response.data
            return errors.map((error) => {
              setError(error.message)
            })
          }
          if (response.status === 401) {
            const { errors } = response.data
            errors.map((error) => {
              setError(error.message)
            })
          } else {
            setIsActivated(true)
          }
        }
      }
      onSubmit()
    }
  }, [activationToken])

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.containerImage}>
          <Image
            src="/static/illustrations/illustration_login.svg"
            alt="Login illustration"
            width={320}
            height={320}
            layout="responsive"
          />
        </div>
        <br />
        <Typography component="h1" variant="h5">
          {isActivated
            ? ` ${dictionary['account_activated']} !`
            : error
              ? dictionary['wrong_link']
              : dictionary['activation']}
        </Typography>
        <br />
        {activationToken && activationToken.length > 1 ? (
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {error ? (
                  <Alert severity="error">
                    <AlertTitle>{dictionary['error']}</AlertTitle>
                    {error}
                  </Alert>
                ) : (
                  <>
                    <Link href="/login" passHref>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        {dictionary['go_to_login']}
                      </Button>
                    </Link>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
